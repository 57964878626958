<template>
  <div class="statement-query">
    <div class="filter-container" style="min-height: auto;width: 100%">
      <el-form :inline="true" :model="queryForm" ref="queryForm" class="form-inline tz" style="width: 100%" >
        <el-row style="width: 100%;background: #fff; padding-top: 15px;">
          <el-form-item label="统计期间" label-width="22%"  style="width: 25%" class="range-query">

            <el-date-picker
                v-model="queryForm.periodStart"
                type="month"
                placeholder="开始时间"
                value-format="yyyy-MM"
                :picker-options="setperiodStartRange"
                :clearable="false"
                :editable="false">
            </el-date-picker>
            至
            <el-date-picker
                v-model="queryForm.periodEnd"
                type="month"
                placeholder="结束时间"
                value-format="yyyy-MM"
                :picker-options="setperiodEndRange"
                :clearable="false"
                :editable="false">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="公司名称" prop="comboSearch" style="width: 19%" label-width="25%" class="single_query">
            <el-select v-model="queryForm.companyId" filterable placeholder="请选择" clearable  class="filter-item">
              <el-option v-for="item in companyIdOptions" :key="item.value" :label="item.value" :value="item.key"/>
            </el-select>
          </el-form-item>
          <el-form-item label="销售方名称" prop="textSearch" style="width: 19%" label-width="30%" class="single_query">
            <el-input placeholder="请输入查询条件" v-model="queryForm.sellerName" maxlength="50" class="filter-item" @keyup.enter.native="handleQuery"/>
          </el-form-item>
          <el-form-item label="对比结果" prop="comboSearch" style="width: 19%" label-width="25%" class="single_query">
            <el-select v-model="queryForm.status" placeholder="请选择" clearable  class="filter-item">
              <el-option v-for="item in statusOptions" :key="item.value" :label="item.key" :value="item.value"/>
            </el-select>
          </el-form-item>
          <el-form-item style="width: 15%" class="button_query">
            <el-button v-waves class="filter-item" style="margin-left: 10%" type="primary" icon="el-icon-search" @click="getList" ref="search">搜索</el-button>
            <el-button v-waves class="filter-item" style="margin-left: 10px;" type="primary" icon="el-icon-refresh" @click="handleReset" >重置</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>

    <div class="batch_button">
      <div class="btnBox">
        <div>
          <el-button class="btn1 iconfont iconicon-04" style="margin-left: 20px" @click="alterSetManageRateVis">&nbsp;管控比例</el-button>
          <el-button class="btn1 iconfont iconicon-04" style="margin-left: 20px" @click="confirmExport">&nbsp;导出</el-button>
        </div>
      </div>
    </div>
    <div class="table" v-loading="isLoading">
      <div class="filter-container">
        <books-table :tableData="tableData" ref="booksTable" @jumpTo='jumpTo(arguments)'/>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[20, 50, 100, 200]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalCount"
            class="pagination">
        </el-pagination>
      </div>
    </div>

    <set-manage-rate ref="setManageRateBox"
                     :visible='setManageRateVis'
                     @confirmDialog='setManageRateConfirm' :cancelDialog='setManageRateCancel' />

  </div>
</template>

<script>
import setManageRate from '@/views/income/statisticalQuery/dialog/setManageRate'
import BooksTable from './table'
import axios from "axios";
export default {
  components: { setManageRate,BooksTable },
  data() {
    return {
      queryForm: {
        periodStart:'',
        periodEnd:'',
        companyId: '',
        status:'',
        sellerName: '',
        page: 1,
        pageSize: 20
      },
      exportFrom:{},
      isExport:false,
      pageSize: 20,
      totalCount:0,
      currentPage:1,
      minQueryDate:null,
      maxQueryDate:null,
      companyIdOptions:[],
      statusOptions:[{'key': '全部','value': ''},{'key': '正常','value': '1'},{'key': '异常','value': '2'},{'key': '失控','value': '3'}],
      tableData: [],
      setperiodStartRange: this.beginDate(),
      setperiodEndRange: this.processDate(),
      setManageRateVis:false,
      isLoading:false,
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize=val;
      this.getList();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage=val;
      this.getList();
      console.log(`当前页: ${this.currentPage}`);
    },
    beginDate(){
      const self = this
      return {
        disabledDate(time){
          if(self.minQueryDate&&self.maxQueryDate){
            return time.getTime() > self.maxQueryDate.getTime() || time.getTime() < self.minQueryDate.getTime();
          }else{
            return null;
          }
        }
      }
    },
    processDate() {
      const  self = this
      return {
        disabledDate(time) {
          if(self.minQueryDate&&self.maxQueryDate&&self.queryForm.periodStart){
            return time.getTime() > self.maxQueryDate.getTime() || time.getTime() < new Date(self.queryForm.periodStart.replace(/-/g,'-')).getTime();
          }else if(!self.minQueryDate&&!self.maxQueryDate&&self.queryForm.periodStart){
            return time.getTime() < new Date(self.queryForm.periodStart.replace(/-/g,'-')).getTime();
          }else if(self.minQueryDate&&self.maxQueryDate&&!self.queryForm.periodStart){
            return time.getTime() > self.maxQueryDate.getTime() || time.getTime() < self.minQueryDate.getTime();
          }else{
            return null;
          }
        }
      }
    },
    dateToStr(date){
      var year = date.getFullYear();//年
      var month = date.getMonth();//月
      return year + "-" +
          ((month + 1) > 9 ? (month + 1) : "0" + (month + 1));
    },
    initQueryDate(){
      let dataStr =this.dateToStr(new Date());
      this.queryForm.periodStart=dataStr;
      this.queryForm.periodEnd=dataStr;
      let _this=this;
      this.$store.dispatch("FindStatisticalQueryPeriod",
          {
            agencyCompanyId: _this.getToken('agencyCompanyId'),
          }
      ).then(res => {
        if (res.success && res.data.length > 0) {
          _this.minQueryDate=new Date(res.data[0].replace(/-/g,'-'));
          _this.maxQueryDate=new Date(res.data[1].replace(/-/g,'-'));
        }
      }).catch(err => {
      })
    },
    //重置
    handleReset() {
      this.queryForm.companyId='';
      this.queryForm.status='';
      this.queryForm.sellerName='';
      this.getList()
    },
    getList(){
      let _this=this;
      if(_this.queryForm.periodStart==''||_this.queryForm.periodStart==null){
        einvAlert.warning("提示","请填写起始日期");
        return
      }
      if(_this.queryForm.periodEnd==''||_this.queryForm.periodEnd==null){
        einvAlert.warning("提示","请填写截止日期");
        return
      }

      _this.isLoading=true;
      this.$store.dispatch("FindStatisticalQueryList",
          {
            agencyCompanyId: _this.getToken('agencyCompanyId'),
            userId: _this.getToken('userId'),
            invoiceType:'1',
            ..._this.queryForm,
          }
      ).then(res => {
        if (res.success && res.data.length > 0) {
          _this.isLoading = false;
          _this.tableData = res.data;
          _this.total = res.totalCount

        } else {
          _this.tableData=[];
          _this.isLoading = false;
        }
      }).catch(err => {
        _this.isLoading = false;
        // einvAlert.error("提示",err)
      })
    },
    alterSetManageRateVis: function () {
      let _this = this;
      _this.$refs.setManageRateBox.getManageRate();
      _this.setManageRateVis = true;
    },
    confirmExport() {
      let _this=this;
      _this.isLoading = true;
      axios({
        method: 'get',
        url: '/api/einvoice/statement/export',
        responseType: 'blob',
        params: {
          agencyCompanyId: _this.getToken('agencyCompanyId'),
          userId: _this.getToken('userId'),
          ..._this.queryForm,
          invoiceType:'1',
        },
        headers: {
          'Content-Type': "application/json;charset=utf-8",
          'Authorization': this.getCookies('Authorization'),
          'group' :process.env.VUE_APP_GROUP
        }
      }).then((res) => {
        let data = res.data
        let fileReader = new FileReader();
        fileReader.onload = function() {
          try {
            let jsonData = JSON.parse(this.result);  // 说明是普通对象数据，后台转换失败
            if (jsonData.status == '40301') { // 接口返回的错误信息
              einvAlert.error("错误",jsonData.message) // 弹出的提示信息
            }
          } catch (err) {   // 解析成对象失败，说明是正常的文件流
            let url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url;
            var now = window.formatDate(new Date(), 'yyyyMMddhhmmss')
            link.setAttribute('download', '进项票统计报表' + now + '.xls')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        };
        fileReader.readAsText(data)  // 注意别落掉此代码，可以将 Blob 或者 File 对象转根据特殊的编码格式转化为内容(字符串形式)
        _this.isLoading = false;
      }).catch(err=>{
        _this.isLoading = false;
      })
    },
    getManageRate(){

    },
    setManageRateConfirm(){
      this.setManageRateVis = false;
      this.getList();
    },
    setManageRateCancel(){
      this.setManageRateVis = false;
    },
    setCompanyList(){
      let _this=this;
      this.$store.dispatch("FindCompanyByUser",
          {
            userId: _this.getToken('userId'),
          }
      ).then(res => {
        _this.companyIdOptions.push({'key':'','value':'全部'});
        _this.queryForm.companyId =_this.getToken('companyId');
        if (res.success && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            _this.companyIdOptions.push({'key':res.data[i].id,'value':res.data[i].name});
          }
        }
        this.getList();
      }).catch(err => {
      })

    },
    jumpTo(msg){
      let json ={'periodStart':this.queryForm.periodStart,'periodEnd':this.queryForm.periodEnd,'sellerName': msg[2]};
      this.setToken('statisticalQueryForm',json);
      if(msg[1]==this.getToken('companyId')){
        this.$router.push(msg[0]+'?initQueryFormType=1');
      }else{
        this.$router.push(msg[0]+'?initQueryFormType=2');
      }
    }
  },
  mounted() {
    // 初始化查询时间
    this.initQueryDate();
    this.setCompanyList();
  },
  watch:{
    queryForm:{
      deep: true,
      immediate:true,
      handler(){

      }
    }
  }
}
</script>


<style lang="less" >
.statement-query {
  .el-form-item--medium{
    margin-bottom: 15px;
  }
  th{
    padding:  0 !important;
    height: 40px !important;
  }
  .el-date-editor--month{
     width: 120px;
  }
  .single_query {
    .el-form-item__content {
      width: 60% !important;
    }
  }

  .range-query {
    .el-form-item__content {
      width: 75% !important;
    }

    .filter-item {
      width: 45%;
    }
  }

  .tz {
    .el-form-item {
      margin-right: 0 !important;
    }
  }

  .button_query {
    .el-form-item__content {
      width: 100% !important;
    }
  }

  .button {
    margin-right: 10px;
  }

  .batch_button {
    padding-top: 10px;
    margin-top: 15px;
    background: #ffffff;

    .btn1 {
      margin-right: 10px;
      background: #409eff;
      border: 1px solid #409eff;
      border-radius: 8px;
      color: #ffffff;
      height: 40px;
      width: 120px;
    }

    .btnBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 24px;

      .el-icon-question {
        color: #a7a7a7;
        font-size: 20px;
      }

      .el-icon-question:hover {
        color: #409eff;
        font-size: 20px;
      }
    }
  }
  .table {
    padding: 24px 20px;
    padding-bottom: 3rem;
    background-color: #fff;
    .comp_table {
      border: 1px solid #ebeef5;
    }
    .pagination{
      text-align: right;
      margin-right: 20px;
    }

  }
}
</style>
