<template>
  <msg  :config="config" :type='type' :promptVisible="visible" class="set-manage-rate_msg" title="设置管控比例" :cancelDialog="cancelDialogs" :confirmDialog='saveManageRate' :isdeling='isdeling'>
    <div class="set-manage-rate" >
      <el-form  :rules="formRules"  ref="form" :model="form">
        <el-form-item label="异常比例" prop="abnormalProportion">
          <el-input type="number" v-model="form.abnormalProportion" ></el-input> %
        </el-form-item>
        <el-form-item label="失控比例" prop="loseControlProportion" style="margin-top: 30px">
          <el-input type="number" v-model="form.loseControlProportion" ></el-input> %
        </el-form-item>
        <el-radio-group v-model="form.isGlobal"  class="export_select">
          <el-radio :label="'2'">应用于当前公司</el-radio>
          <el-radio :label="'1'">应用于全部公司</el-radio>
        </el-radio-group>
      </el-form>
    </div>
  </msg>
</template>

<script>
  export default {
    props: {
      visible: {
        type: Boolean,
      },
      isdeling:{
        type: Boolean,
      },
      cancelDialog: {
        type: Function,
        default: () => { },
      },
    },
    components: {

    },
    data() {
      var checkAbnormalProportion = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入正确的异常比例'));
        };
        var reg=  /^\d{1,2}(\.[0-9]{1,2})?$/;
        value=Number(value);
        if (value < 0) {
          return callback(new Error('必须大于等于0'));
        } else if (value >= 100) {
          return callback(new Error('必须小于100'));
        } else if (!reg.test(value)) {
          return callback("请输入不超过两位小数的数字");
        } else {
          return callback();
        }

      };
      var checkLoseControlProportion = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入正确的失控比例'));
        }
        var reg=  /^\d{1,2}(\.[0-9]{1,2})?$/;
        value=Number(value);
        if (value <= 0) {
          return callback(new Error('必须大于0'));
        } else if (value > 100) {
          return callback(new Error('必须小于等于100'));
        } else if (this.form.abnormalProportion&&value <= Number(this.form.abnormalProportion)) {
          return callback(new Error('必须大于异常比例'));
        } else if (!reg.test(value)&&value!=100) {
          return callback("请输入不超过两位小数的数字");
        } else{
          return callback();
        }
      };


      return {
        config: {
          top: '25vh',
          width: '20rem',
          center: true,
          btnTxt: ['确定','取消'],
        },
        type:'warning',
        form:{
          id:'',
          abnormalProportion:'',
          loseControlProportion:'',
          isGlobal:'1',
        },
        formRules: {
          abnormalProportion: [
            { validator: checkAbnormalProportion, trigger: 'blur' }
          ],
          loseControlProportion: [
            { validator: checkLoseControlProportion, trigger: 'blur' }
          ],
        }
      };
    },
    methods: {
      cancelDialogs(){
        this.cancelDialog();
      },
      getManageRate: function () {
        let _this = this;
        this.$store.dispatch("ControlProportionFind",
            {
              userId: _this.getToken('userId'),
              companyId: _this.getToken('companyId'),
            }
        ).then(res => {
          if (res.success && res.data != null) {
            _this.form.abnormalProportion = res.data.abnormalProportion;
            _this.form.loseControlProportion = res.data.loseControlProportion;
            _this.form.isGlobal = res.data.isGlobal;
            _this.form.id = res.data.id;

          } else {
            einvAlert.error("提示", "未找到相关数据");
          }
        }).catch(err => {
        })
      },
      saveManageRate: function () {
        let _this = this;
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.$store.dispatch("ControlProportionUpdate",
                {
                  id:_this.form.id,
                  companyId: _this.getToken('companyId'),
                  abnormalProportion:_this.form.abnormalProportion,
                  loseControlProportion:_this.form.loseControlProportion,
                  isGlobal :_this.form.isGlobal
                }
            ).then(res => {
              if (res.success ) {
                einvAlert.success("提示", "保存成功");
                _this.$emit('confirmDialog');
              } else {
                einvAlert.error("提示", "保存失败");
              }
            }).catch(err => {
            })
          } else {
            return false;
          }
        });

      },
    },
    watch:{
    }
  };
</script>

<style lang="less" scoped>

.set-manage-rate{
  padding:  0 10px;
  .el-input-row{
    text-align: left;
    margin: 10px 0px;
    .el-input{
      width: 50%;
      margin: 0 20px;
    }
  }
  .export_select {
    text-align: left;
    margin-top: 20px;
    width: 100%;
    /deep/ label {
      width: 40%;
      text-align: left;
      margin-bottom: 20px;
      font-size: 16px !important;
    }
  }
}
</style>
<style lang="less">
 .set-manage-rate_msg{
   .el-dialog__body{
     padding-bottom: 0px;
   }
   .el-form-item__content{
     width: 70%;
     display: inline-block;
   }
   .el-input--medium{
     width: 85%;
     margin-right: 5px;
   }

 }
</style>